module.exports = {
  home: 'Home',
  notFound: 'Not Found Page',
  prev: '前へ',
  next: '次へ',
  toc: 'Table Of Contents',
  searchLabel: 'search',
  searchResult: '検索結果',
  articles: 'の記事一覧'
};
